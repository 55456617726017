/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Clever Analytics Users Public API
 * OpenAPI spec version: 1.0.0
 */
import { usersInstance } from '../../api/client';
export type GetUsers1ApprovalState = typeof GetUsers1ApprovalState[keyof typeof GetUsers1ApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsers1ApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export type GetUsers1SortDir = typeof GetUsers1SortDir[keyof typeof GetUsers1SortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsers1SortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetUsers1SortCol = typeof GetUsers1SortCol[keyof typeof GetUsers1SortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsers1SortCol = {
  ID: 'ID',
  EMAIL: 'EMAIL',
  CREATED: 'CREATED',
} as const;

export type GetUsers1Params = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetUsers1SortCol;
sortDir?: GetUsers1SortDir;
/**
 * Search in user email, company name and number
 */
search?: string;
/**
 * User company relation approval state
 */
approvalState?: GetUsers1ApprovalState;
};

export type GetCompanyUsersStateItem = typeof GetCompanyUsersStateItem[keyof typeof GetCompanyUsersStateItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyUsersStateItem = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export type GetCompanyUsersParams = {
state?: GetCompanyUsersStateItem[];
};

export type GetCompanies1PlanSource = typeof GetCompanies1PlanSource[keyof typeof GetCompanies1PlanSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanies1PlanSource = {
  TRIAL: 'TRIAL',
  MANUAL: 'MANUAL',
  PAID: 'PAID',
} as const;

export type GetCompanies1Plan = typeof GetCompanies1Plan[keyof typeof GetCompanies1Plan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanies1Plan = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export type GetCompanies1SortDir = typeof GetCompanies1SortDir[keyof typeof GetCompanies1SortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanies1SortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetCompanies1SortCol = typeof GetCompanies1SortCol[keyof typeof GetCompanies1SortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanies1SortCol = {
  ID: 'ID',
  NAME: 'NAME',
  PLAN_EXPIRATION: 'PLAN_EXPIRATION',
} as const;

export type GetCompanies1Params = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetCompanies1SortCol;
sortDir?: GetCompanies1SortDir;
/**
 * Company country
 */
country?: string;
/**
 * Search in company name and number
 */
search?: string;
/**
 * Plan type
 */
plan?: GetCompanies1Plan;
/**
 * Plan source
 */
planSource?: GetCompanies1PlanSource;
/**
 * Plan is (not) expired 
 */
planValid?: string;
};

export type GetUsersSortDir = typeof GetUsersSortDir[keyof typeof GetUsersSortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersSortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetUsersSortCol = typeof GetUsersSortCol[keyof typeof GetUsersSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersSortCol = {
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  CREATED: 'CREATED',
} as const;

export type GetUsersParams = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetUsersSortCol;
sortDir?: GetUsersSortDir;
/**
 * Search in name and email
 */
search?: string;
/**
 * User country
 */
country?: string;
};

export type CompanyUserToCompanyPlan = typeof CompanyUserToCompanyPlan[keyof typeof CompanyUserToCompanyPlan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserToCompanyPlan = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export type CompanyUserToApprovalState = typeof CompanyUserToApprovalState[keyof typeof CompanyUserToApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserToApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanyUserTo {
  approvalState: CompanyUserToApprovalState;
  companyCountry?: string;
  companyId: number;
  companyName?: string;
  companyNumber?: string;
  companyPlan?: CompanyUserToCompanyPlan;
  companyPlanExpiresAt?: string;
  createdAt: string;
  id: number;
  userEmail?: string;
  userId: string;
  userMetadata: UserMetadata;
  userName?: string;
}

export interface CompanyUserPage {
  data: CompanySimpleUserTo[];
  totalSize: number;
}

export interface CompanyPage {
  data: CompanyTo[];
  totalSize: number;
}

export type CountryToPopulatorFieldsItem = typeof CountryToPopulatorFieldsItem[keyof typeof CountryToPopulatorFieldsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryToPopulatorFieldsItem = {
  VAT: 'VAT',
  FORM: 'FORM',
  ADDRESS: 'ADDRESS',
} as const;

export interface CountryTo {
  hasCompanyPopulator: boolean;
  hasCompanyValidator: boolean;
  id: string;
  isAuthorized: boolean;
  isCompanyRequired: boolean;
  language: string;
  localName: string;
  name: string;
  phoneCountryCode: string;
  populatorFields: CountryToPopulatorFieldsItem[];
}

export interface UserPage {
  data: UserTo[];
  totalSize: number;
}

export interface UserMetadata {
  can: CanMetadata;
  common: CommonMetadata;
}

export interface UserTo {
  company: CompanySimpleUserTo[];
  createdAt: string;
  email: string;
  id: string;
  identities: string[];
  isBlocked: boolean;
  lastLogin: string;
  loginsCount: number;
  name: string;
  phone?: PhoneTo;
  userMetadata: UserMetadata;
}

export interface PhoneMetadata {
  countryCode?: string;
  phoneNumber?: string;
}

export interface NewsMetadata {
  language: string;
}

export interface SettingsMetadata {
  language: string;
  news: NewsMetadata;
}

export type CompanySimpleUserToCompanyPlan = typeof CompanySimpleUserToCompanyPlan[keyof typeof CompanySimpleUserToCompanyPlan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySimpleUserToCompanyPlan = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export type CompanySimpleUserToApprovalState = typeof CompanySimpleUserToApprovalState[keyof typeof CompanySimpleUserToApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySimpleUserToApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanySimpleUserTo {
  approvalState: CompanySimpleUserToApprovalState;
  companyCountry?: string;
  companyId: number;
  companyName?: string;
  companyNumber?: string;
  companyPlan?: CompanySimpleUserToCompanyPlan;
  companyPlanExpiresAt?: string;
  createdAt: string;
  id: number;
  userEmail?: string;
  userId: string;
}

export interface CompanyMetadata {
  companyName?: string;
  companyNumber?: string;
  countryCode?: string;
}

export interface CommonMetadata {
  phone: PhoneMetadata;
}

export interface CanMetadata {
  companies?: CompanyMetadata[];
  settings: SettingsMetadata;
}

export interface LanguageTo {
  id: string;
  isDefault: boolean;
  localName: string;
  name: string;
}

export type CompanyPatchRequestCompanyForm = typeof CompanyPatchRequestCompanyForm[keyof typeof CompanyPatchRequestCompanyForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPatchRequestCompanyForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export interface CompanyPatchRequest {
  address?: string;
  companyForm?: CompanyPatchRequestCompanyForm;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: PhoneUpdateRequest;
  contactPosition?: string;
  vat?: string;
}

export interface UserPatchRequest {
  company?: UserUpdateCompanyRequest;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?: string;
  name?: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  newsLanguage?: string;
  phone?: PhoneUpdateRequest;
}

export interface CompanyValidationResponse {
  name?: string;
}

export interface CompanyValidationRequest {
  companyNumber: string;
  countryCode: string;
}

export type CompanyPlanAppMetadataType = typeof CompanyPlanAppMetadataType[keyof typeof CompanyPlanAppMetadataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPlanAppMetadataType = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export type CompanyPlanAppMetadataSource = typeof CompanyPlanAppMetadataSource[keyof typeof CompanyPlanAppMetadataSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPlanAppMetadataSource = {
  TRIAL: 'TRIAL',
  MANUAL: 'MANUAL',
  PAID: 'PAID',
} as const;

export interface CompanyPlanAppMetadata {
  expiresAt?: string;
  source?: CompanyPlanAppMetadataSource;
  type?: CompanyPlanAppMetadataType;
}

export type CompanyAppMetadataCompanyForm = typeof CompanyAppMetadataCompanyForm[keyof typeof CompanyAppMetadataCompanyForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAppMetadataCompanyForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export type CompanyAppMetadataApprovalState = typeof CompanyAppMetadataApprovalState[keyof typeof CompanyAppMetadataApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAppMetadataApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanyAppMetadata {
  approvalState: CompanyAppMetadataApprovalState;
  companyAddress?: string;
  companyContactEmail?: string;
  companyContactName?: string;
  companyContactPhoneCountry?: string;
  companyContactPhoneNumber?: string;
  companyContactPosition?: string;
  companyForm?: CompanyAppMetadataCompanyForm;
  companyName?: string;
  companyNumber?: string;
  companyVat?: string;
  countryCode?: string;
  plan: CompanyPlanAppMetadata;
}

export interface CompanyMetadataRequest {
  companyNumber: string;
  countryCode: string;
}

export interface ErrorResponse {
  errors?: string[];
  message?: string;
  status: string;
  timestamp: number;
}

export type CompanyPutRequestCompanyForm = typeof CompanyPutRequestCompanyForm[keyof typeof CompanyPutRequestCompanyForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPutRequestCompanyForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export interface CompanyPutRequest {
  address: string;
  companyForm: CompanyPutRequestCompanyForm;
  contactEmail: string;
  contactName: string;
  contactPhone: PhoneUpdateRequest;
  contactPosition: string;
  vat: string;
}

export interface PhoneTo {
  countryCode: string;
  phoneNumber: string;
}

export type CompanyToPlanType = typeof CompanyToPlanType[keyof typeof CompanyToPlanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyToPlanType = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export type CompanyToPlanSource = typeof CompanyToPlanSource[keyof typeof CompanyToPlanSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyToPlanSource = {
  TRIAL: 'TRIAL',
  MANUAL: 'MANUAL',
  PAID: 'PAID',
} as const;

export type CompanyDetailToForm = typeof CompanyDetailToForm[keyof typeof CompanyDetailToForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyDetailToForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export interface CompanyDetailTo {
  address?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: PhoneTo;
  contactPosition?: string;
  form?: CompanyDetailToForm;
  vat?: string;
}

export interface CompanyTo {
  companyNumber: string;
  country: string;
  detail?: CompanyDetailTo;
  id: number;
  name: string;
  planExpiresAt?: string;
  planSource: CompanyToPlanSource;
  planType: CompanyToPlanType;
}

export type CompanyPlanPutRequestPlan = typeof CompanyPlanPutRequestPlan[keyof typeof CompanyPlanPutRequestPlan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPlanPutRequestPlan = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export interface CompanyPlanPutRequest {
  expiresAt: string;
  plan: CompanyPlanPutRequestPlan;
}

export type UserUserMetadata = {[key: string]: { [key: string]: unknown }};

export type UserAppMetadata = {[key: string]: { [key: string]: unknown }};

export interface ProfileData {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  name?: string;
  phone_number?: string;
  phone_verified?: boolean;
  username?: string;
}

export interface Identity {
  access_token?: string;
  connection?: string;
  isSocial?: boolean;
  profileData?: ProfileData;
  provider?: string;
  user_id?: string;
}

export interface User {
  app_metadata?: UserAppMetadata;
  blocked?: boolean;
  client_id?: string;
  connection?: string;
  created_at?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  identities?: Identity[];
  last_ip?: string;
  last_login?: string;
  last_password_reset?: string;
  logins_count?: number;
  multifactor?: string[];
  name?: string;
  nickname?: string;
  /** @deprecated */
  password?: string[];
  phone_number?: string;
  phone_verified?: boolean;
  picture?: string;
  updated_at?: string;
  user_id?: string;
  user_metadata?: UserUserMetadata;
  username?: string;
  verify_email?: boolean;
  verify_password?: boolean;
  verify_phone_number?: boolean;
}

export interface UserUpdateCompanyRequest {
  companyNumber: string;
  countryCode: string;
}

export interface PhoneUpdateRequest {
  countryCode: string;
  phoneNumber: string;
}

export interface UserUpdateRequest {
  company?: UserUpdateCompanyRequest;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language: string;
  name: string;
  phone: PhoneUpdateRequest;
}





  /**
 * @summary List of all users
 */
export const getUsers = (
    params?: GetUsersParams,
 ) => {
      return usersInstance<UserPage>(
      {url: `/can-users/api/user`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Update user profile information
 */
export const update = (
    userUpdateRequest: UserUpdateRequest,
 ) => {
      return usersInstance<User>(
      {url: `/can-users/api/user`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userUpdateRequest
    },
      );
    }
  
/**
 * @summary Patch user profile information
 */
export const patch = (
    userPatchRequest: UserPatchRequest,
 ) => {
      return usersInstance<User>(
      {url: `/can-users/api/user`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userPatchRequest
    },
      );
    }
  
/**
 * @summary Block user from logging in
 */
export const block = (
    userId: string,
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}/block`, method: 'PUT'
    },
      );
    }
  
/**
 * @summary Unblock user
 */
export const unblock = (
    userId: string,
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}/block`, method: 'DELETE'
    },
      );
    }
  
/**
 * @summary Update company plan
 */
export const updateCompanyPlan = (
    companyId: number,
    companyPlanPutRequest: CompanyPlanPutRequest,
 ) => {
      return usersInstance<CompanyTo>(
      {url: `/can-users/api/company/${companyId}/plan`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: companyPlanPutRequest
    },
      );
    }
  
/**
 * @summary Get company detail information by id
 */
export const getCompanyDetail = (
    companyId: number,
 ) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/${companyId}/detail`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Update company detail information by id
 */
export const updateCompanyDetail = (
    companyId: number,
    companyPutRequest: CompanyPutRequest,
 ) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/${companyId}/detail`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: companyPutRequest
    },
      );
    }
  
/**
 * @summary Update company detail information by id
 */
export const patchCompanyDetail = (
    companyId: number,
    companyPatchRequest: CompanyPatchRequest,
 ) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/${companyId}/detail`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: companyPatchRequest
    },
      );
    }
  
/**
 * @summary Set user approval state in a company
 */
export const setUserState = (
    userId: number,
    state: 'WAITING' | 'APPROVED' | 'BLOCKED',
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/company/user/${userId}/${state}`, method: 'PUT'
    },
      );
    }
  
/**
 * @summary Returns internal company metadata for multiple companies
 */
export const getList = (
    user: string,
    companyMetadataRequest: CompanyMetadataRequest[],
 ) => {
      return usersInstance<CompanyAppMetadata[]>(
      {url: `/can-users/internal/api/company/${user}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyMetadataRequest
    },
      );
    }
  
/**
 * @summary Validate company identification number in country specific registry
 */
export const validate = (
    companyValidationRequest: CompanyValidationRequest,
 ) => {
      return usersInstance<CompanyValidationResponse>(
      {url: `/can-users/api/company/validate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyValidationRequest
    },
      );
    }
  
/**
 * @summary List of all available languages
 */
export const getLanguages = (
    
 ) => {
      return usersInstance<LanguageTo[]>(
      {url: `/can-users/public/api/language`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Language detail
 */
export const getLanguage = (
    id: string,
 ) => {
      return usersInstance<LanguageTo>(
      {url: `/can-users/public/api/language/${id}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Returns internal company metadata for provided company number
 */
export const get = (
    country: string,
    company: string,
    user: string,
 ) => {
      return usersInstance<CompanyAppMetadata>(
      {url: `/can-users/internal/api/company/${country}/${company}/${user}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Get user detail
 */
export const getUser = (
    userId: string,
 ) => {
      return usersInstance<UserTo>(
      {url: `/can-users/api/user/${userId}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Permanently delete user and their company relations
 */
export const _delete = (
    userId: string,
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}`, method: 'DELETE'
    },
      );
    }
  
/**
 * @deprecated
 * @summary Fix events in app metadata
 */
export const fixAppMetadata = (
    
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/fix-app-metadata`, method: 'GET'
    },
      );
    }
  
/**
 * @summary List of all user companies
 */
export const getCompanies = (
    
 ) => {
      return usersInstance<CompanySimpleUserTo[]>(
      {url: `/can-users/api/user/company`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Get company by id
 */
export const getCompany = (
    companyId: number,
 ) => {
      return usersInstance<CompanyTo>(
      {url: `/can-users/api/user/company/${companyId}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary List of all available countries
 */
export const getCountries = (
    
 ) => {
      return usersInstance<CountryTo[]>(
      {url: `/can-users/api/country`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Country detail
 */
export const getCountry = (
    id: string,
 ) => {
      return usersInstance<CountryTo>(
      {url: `/can-users/api/country/${id}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary List of all companies
 */
export const getCompanies1 = (
    params?: GetCompanies1Params,
 ) => {
      return usersInstance<CompanyPage>(
      {url: `/can-users/api/company`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Get company by id
 */
export const getCompany1 = (
    companyId: number,
 ) => {
      return usersInstance<CompanyTo>(
      {url: `/can-users/api/company/${companyId}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary List of all company users
 */
export const getCompanyUsers = (
    companyId: number,
    params?: GetCompanyUsersParams,
 ) => {
      return usersInstance<CompanySimpleUserTo[]>(
      {url: `/can-users/api/company/${companyId}/user`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary List of all users with company relation
 */
export const getUsers1 = (
    params?: GetUsers1Params,
 ) => {
      return usersInstance<CompanyUserPage>(
      {url: `/can-users/api/company/user`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Get user company relation
 */
export const getUser1 = (
    userId: number,
 ) => {
      return usersInstance<CompanyUserTo>(
      {url: `/can-users/api/company/user/${userId}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Get user company relation
 */
export const deleteUser = (
    userId: number,
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/company/user/${userId}`, method: 'DELETE'
    },
      );
    }
  
/**
 * @summary Permanently deletes company and all its users
 */
export const deleteTestCompany = (
    
 ) => {
      return usersInstance<void>(
      {url: `/can-users/internal/api/company/test`, method: 'DELETE'
    },
      );
    }
  
/**
 * @summary Reset user and their company relations
 */
export const reset = (
    userId: string,
 ) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}/reset`, method: 'DELETE'
    },
      );
    }
  
export type GetUsersResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type UpdateResult = NonNullable<Awaited<ReturnType<typeof update>>>
export type PatchResult = NonNullable<Awaited<ReturnType<typeof patch>>>
export type BlockResult = NonNullable<Awaited<ReturnType<typeof block>>>
export type UnblockResult = NonNullable<Awaited<ReturnType<typeof unblock>>>
export type UpdateCompanyPlanResult = NonNullable<Awaited<ReturnType<typeof updateCompanyPlan>>>
export type GetCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof getCompanyDetail>>>
export type UpdateCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof updateCompanyDetail>>>
export type PatchCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof patchCompanyDetail>>>
export type SetUserStateResult = NonNullable<Awaited<ReturnType<typeof setUserState>>>
export type GetListResult = NonNullable<Awaited<ReturnType<typeof getList>>>
export type ValidateResult = NonNullable<Awaited<ReturnType<typeof validate>>>
export type GetLanguagesResult = NonNullable<Awaited<ReturnType<typeof getLanguages>>>
export type GetLanguageResult = NonNullable<Awaited<ReturnType<typeof getLanguage>>>
export type GetResult = NonNullable<Awaited<ReturnType<typeof get>>>
export type GetUserResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type _DeleteResult = NonNullable<Awaited<ReturnType<typeof _delete>>>
export type FixAppMetadataResult = NonNullable<Awaited<ReturnType<typeof fixAppMetadata>>>
export type GetCompaniesResult = NonNullable<Awaited<ReturnType<typeof getCompanies>>>
export type GetCompanyResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>
export type GetCountriesResult = NonNullable<Awaited<ReturnType<typeof getCountries>>>
export type GetCountryResult = NonNullable<Awaited<ReturnType<typeof getCountry>>>
export type GetCompanies1Result = NonNullable<Awaited<ReturnType<typeof getCompanies1>>>
export type GetCompany1Result = NonNullable<Awaited<ReturnType<typeof getCompany1>>>
export type GetCompanyUsersResult = NonNullable<Awaited<ReturnType<typeof getCompanyUsers>>>
export type GetUsers1Result = NonNullable<Awaited<ReturnType<typeof getUsers1>>>
export type GetUser1Result = NonNullable<Awaited<ReturnType<typeof getUser1>>>
export type DeleteUserResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
export type DeleteTestCompanyResult = NonNullable<Awaited<ReturnType<typeof deleteTestCompany>>>
export type ResetResult = NonNullable<Awaited<ReturnType<typeof reset>>>
